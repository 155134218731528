import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import AutoNumeric from 'autonumeric';
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["table", "mapping_dropdown", "mapping_checkbox",
    "transaction_currency_radio_template", "modal_currencies_radio",
    "modal_currency_text",
    "to_main_rate_field", "from_main_rate_field",
    "to_additional_rate_field", "from_additional_rate_field",
    "filter_period", "filter_transaction_type", "filter_purpose",
    "filter_account", "filter_pay_type", "filter_associated", 
    "filter_search"]


  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }

  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      const rowId = data.id;
      const rowAction = data.row_action;
      const rowData = data.data;

      // update table data
      this.updateTable(rowId, rowAction, rowData);

      const table = document.getElementById("cashbox_transactions_totals_table")
      table.querySelector(".totals_incomes").innerHTML  = data.total_incomes;
      table.querySelector(".totals_expenses").innerHTML = data.total_expenses;

      // hide form modal
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_md.setContent(data.form_content)
      } else {
        window.layout_modal_md.hide();
      }
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    window.layout_modal_md.setContent(data.form_content)
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");

    let filters = []
    if (this.hasFilter_periodTarget && this.filter_periodTarget.value != '') {
      filters.push("start_at_end_at_period=" + this.filter_periodTarget.value);
    }
    if (this.hasFilter_transaction_typeTarget && this.filter_transaction_typeTarget.choices.getValue(true) != '') {
      filters.push("transaction_type_ids=" + this.filter_transaction_typeTarget.choices.getValue(true));
    }
    if (this.hasFilter_purposeTarget && this.filter_purposeTarget.choices.getValue(true) != '') {
      filters.push("purpose_ids=" + this.filter_purposeTarget.choices.getValue(true));
    }
    if (this.hasFilter_accountTarget && this.filter_accountTarget.choices.getValue(true) != '') {
      filters.push("account_ids=" + this.filter_accountTarget.choices.getValue(true));
    }
    if (this.hasFilter_pay_typeTarget && this.filter_pay_typeTarget.choices.getValue(true) != '') {
      filters.push("pay_type_ids=" + this.filter_pay_typeTarget.choices.getValue(true));
    }
    if (this.hasFilter_associatedTarget && this.filter_associatedTarget.choices.getValue(true) != '') {
      filters.push("associated_ids=" + this.filter_associatedTarget.choices.getValue(true));
    }
    filters = filters.join("&")
    if (filters.length) {
      url += (url.search(/\?/) > 0 ? "&" : "?") + filters
    }

    this.openLayoutModalMD(url);
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }


  updateTransactionCurrencies(event) {
    let currencies = event.currentTarget.getAttribute("data-account-currencies");
    let li_template = this.transaction_currency_radio_templateTarget.innerHTML;
    let currencies_ul = this.modal_currencies_radioTarget;

    currencies_ul.innerHTML = "";
    if (currencies) {
      JSON.parse(currencies).forEach(el => {
        let li = document.createElement("li");
        let currency_class = (el["disabled"] == true ? "disabled" : "")
        let li_html = li_template.replace(/TRANSACTION_AMOUNT_CURRENCY_CODE/g, el["currency"]);
        li_html = li_html.replace(/TRANSACTION_AMOUNT_CURRENCY_CLASS/g, currency_class);
        li_html = li_html.replace(/\"TRANSACTION_CURRENCY_INFO\"/g, JSON.stringify(el));

        li.classList.add("nav-item");
        li.innerHTML = li_html;
        currencies_ul.appendChild(li);
      });

      let currency = currencies_ul.querySelector("li:first-child input[type=radio]");
      currency.checked = true;

      this.updateTransactionInputCurrencies(currency);
    }
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget;

    this.updateTransactionInputCurrencies(currency);
  }

  updateTransactionInputCurrencies(element) {
    let currency = element.getAttribute("value");
    let info_data = element.getAttribute("data-currency-info");
    // let to_main_field = this.to_main_rate_fieldTarget;
    let from_main_field = this.from_main_rate_fieldTarget;
    let to_additional_field = this.to_additional_rate_fieldTarget;
    // let from_additional_field = this.from_additional_rate_fieldTarget;

    this.modal_currency_textTarget.innerHTML = currency;

    if (info_data){
      info_data = JSON.parse(info_data);

      if (info_data["is_main"] == true){
        // to_main_field.classList.add("hidden");
        // AutoNumeric.set(to_main_field.querySelector(".input-group input"), 1.0);

        from_main_field.classList.add("hidden");
        AutoNumeric.set(from_main_field.querySelector(".input-group input"), 1.0);
      } else {
        // to_main_field.classList.remove("hidden");
        // to_main_field.querySelector("label.control-label span.text").innerHTML = info_data["label"] + ' = ';
        // AutoNumeric.set(to_main_field.querySelector(".input-group input"), info_data["to_main_rate"] || 1.0);

        from_main_field.classList.remove("hidden");
        from_main_field.querySelector(".input-group span.input-group-text").innerHTML = currency;
        AutoNumeric.set(from_main_field.querySelector(".input-group input"), info_data["from_main_rate"] || 1.0);
      }

      if (info_data["is_additional"] == true){
        to_additional_field.classList.add("hidden");
        AutoNumeric.set(to_additional_field.querySelector(".input-group input"), 1.0);

        // from_additional_field.classList.add("hidden");
        // AutoNumeric.set(from_additional_field.querySelector(".input-group input"), 1.0);
      } else {
        to_additional_field.classList.remove("hidden");
        to_additional_field.querySelector("label.control-label span.text").innerHTML = info_data["label"] + ' = ';
        AutoNumeric.set(to_additional_field.querySelector(".input-group input"), info_data["to_additional_rate"] || 1.0);

        // from_additional_field.classList.remove("hidden");
        // from_additional_field.querySelector(".input-group span.input-group-text").innerHTML = currency;
        // AutoNumeric.set(from_additional_field.querySelector(".input-group input"), info_data["from_additional_rate"] || 1.0);
      }
    }
  }

  filterTable() {
    this.setFilterToTable();
  }

  // Main table filters
  setFilterToTable() {
    let table = this.tableTarget.tabulator;
    let filters = this.getPageFilters();

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  getPageFilters() {
    let filters = []

    if (this.hasFilter_searchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.filter_searchTarget.value
      })
    }

    if (this.hasFilter_periodTarget) {
      filters.push({
        field: "start_at_end_at_period",
        type: "=",
        value: this.filter_periodTarget.value || this.filter_periodTarget.getAttribute("data-period")
      })
    }

    if (this.hasFilter_transaction_typeTarget) {
      filters.push({
        field: "transaction_type_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.filter_transaction_typeTarget)
      })
    }

    if (this.hasFilter_purposeTarget) {
      filters.push({
        field: "purpose_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.filter_purposeTarget)
      })
    }

    if (this.hasFilter_accountTarget) {
      filters.push({
        field: "account_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.filter_accountTarget)
      })
    }

    if (this.hasFilter_pay_typeTarget) {
      filters.push({
        field: "pay_type_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.filter_pay_typeTarget)
      })
    }

    if (this.hasFilter_associatedTarget) {
      filters.push({
        field: "associated_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.filter_associatedTarget)
      })
    }

    return filters;
  }

  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    const footer_labels = JSON.parse(this.data.get("footer-labels"));

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 180) + "px",
      persistenceID: "cashbox_transactions_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // headerSort: false,
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getPageFilters(),
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      footerElement: "<table class='table table-striped table-heading-fixed' id='cashbox_transactions_totals_table'> \
          <tfoot> \
            <tr>  \
              <td class='text-right' style='width: 30%'> \
                <strong class='font-size-16'>" + footer_labels['total_label'] + "</strong> \
              </td> \
              <td class='text-right td-w-100'/> \
              <td class='text-right td-w-60'> \
                <strong class='font-size-16'>" + footer_labels['incomes_label'] + "</strong> \
              </td> \
              <td class='text-right td-w-100 totals_incomes'/> \
              <td class='text-right td-w-60'> \
                <strong class='font-size-16'>" + footer_labels['expenses_label'] + "</strong> \
              </td> \
              <td class='text-right td-w-100 totals_expenses'/> \
              <td class='text-right' style='width: 20%'/>  \
            </tr>  \
          </tfoot> \
        </table>",
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.cashbox_transaction__status) {
          row.getElement().classList.add("row-" + data.cashbox_transaction__status)
        }
      },
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("cashbox_transactions_totals_table")
        table.querySelector(".totals_incomes").innerHTML = response.total_incomes;
        table.querySelector(".totals_expenses").innerHTML = response.total_expenses;

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async updateTable(rowId, rowAction, rowData, total) {
    const mainTable = document.getElementById("cashbox_transactions_table");
    const mainTabulator = mainTable.tabulator;

    switch (rowAction) {
      case "add":
        mainTabulator.addRow(rowData, true);
        mainTabulator.scrollToRow(rowId, "top", false);
        break;
      case "update":
        mainTabulator.updateRow(rowId, rowData);
        break;
      case "delete":
        mainTabulator.deleteRow(rowId);
        break;
    }

  }


  getSelectCollectionValues(target) {
    if (target.choices) {
      return target.choices.getValue(true)
    } else if (target.getAttribute("data-selected")) {
      return JSON.parse(target.getAttribute("data-selected"));
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.value; });
    }
  }

  setFormat(value) {
    if (!value)
      return 0;
    return AutoNumeric.format(value, {
      allowDecimalPadding: "floats",
      decimalPlaces: 2,
      digitGroupSeparator: " ",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      styleRules: {
        positive: "autoNumeric-positive"
      },
      minimumValue: 0
    });
  }
}